<template>
  <div
    aria-live="assertive"
    class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-50"
  >
    <div class="w-full flex flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="notification"
          :class="[
            'max-w-md w-full shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden',
            notificationStyle.backgroundColor,
          ]"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <component
                  :is="notificationStyle.icon"
                  :class="['h-6 w-6', notificationStyle.iconColor]"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p :class="['text-sm font-medium', notificationStyle.titleColor]">{{ notification.title }}</p>
                <p :class="['mt-1 text-sm', notificationStyle.textColor]" v-if="notification.text">
                  {{ notification.text }}
                </p>
              </div>
              <div class="ml-4 flex-shrink-0 flex">
                <button
                  @click="clearNotification"
                  :class="[
                    'bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                    notificationStyle.backgroundColor,
                  ]"
                >
                  <span class="sr-only">Close</span>
                  <XIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { XCircleIcon, CheckCircleIcon, XIcon } from "@heroicons/vue/solid";

const notificationStyles = new Map();

notificationStyles.set("info", {
  icon: CheckCircleIcon,
  iconColor: "text-green-400",
  backgroundColor: "bg-white",
  titleColor: "text-gray-900",
  textColor: "text-gray-500",
});

notificationStyles.set("error", {
  icon: XCircleIcon,
  iconColor: "text-red-400",
  backgroundColor: "bg-red-50",
  titleColor: "text-red-800",
  textColor: "text-red-700",
});

export default {
  name: "Notification",
  components: {
    XIcon,
    CheckCircleIcon,
  },
  setup() {
    const store = useStore();

    const notification = computed(() => store.state.notification);
    const notificationStyle = computed(() => notificationStyles.get(notification.value.type));

    return {
      notification,
      notificationStyle,
      clearNotification: () => store.commit("clearNotification"),
    };
  },
};
</script>
