<template>
  <div class="min-h-0 flex-1 flex flex-col py-6">
    <div class="relative flex-1 px-4 sm:px-6">
      <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Set devices group</h1>
      <input
        v-model="groupName"
        type="text"
        placeholder="Group name"
        class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
      />
    </div>
  </div>
  <div class="flex-shrink-0 px-4 py-4 flex justify-end">
    <button
      type="button"
      class="
        bg-white
        py-2
        px-4
        border border-gray-300
        rounded-md
        shadow-sm
        text-sm
        font-medium
        text-gray-700
        hover:bg-gray-50
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
      "
      @click="$emit('close')"
    >
      Cancel
    </button>
    <button
      type="submit"
      :disabled="!isValid"
      @click="alertOpen = true"
      class="
        ml-4
        inline-flex
        justify-center
        py-2
        px-4
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        disabled:opacity-50
      "
    >
      Save
    </button>
  </div>
  <SimpleAlert
    @cancel="alertOpen = false"
    @approve="approve"
    :open="alertOpen"
    title="Set group?"
    description="Are you sure you want to set this group name to all selected devices? All existing groups will be overwritten. This action cannot be undone."
    action="Apply"
  ></SimpleAlert>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/Alert";

export default {
  components: {
    SimpleAlert,
  },
  emits: ["close"],
  props: ["deviceSelection"],

  setup(props, { emit }) {
    const groupName = ref("");
    const alertOpen = ref(false);
    const isValid = computed(() => groupName.value.length > 0);
    const store = useStore();

    return {
      isValid,
      groupName,
      alertOpen,
      async approve() {
        alertOpen.value = false;
        await store.dispatch("device/batchUpdateGroup", {
          deviceIds: props.deviceSelection,
          groupName: groupName.value,
        });

        await store.dispatch("showNotification", {
          title: "Successfully saved!",
          text: "",
          type: "info",
        });
        emit("close");
      },
    };
  },
};
</script>
