export default {
  en: {
    search: {
      placeholder: "Search devices",
    },
    profileMenu: {
      signOut: "Sign out",
    },
    filters: {
      all: "All",
      bracelet: "Bracelet",
      error: "Errors",
    },
    navigationBar: {
      alarmCenters: "Alarm Centers",
      users: "Users",
    },
    errors: {
      unknown: "Unknown error",
    },
  },
  de: {
    search: {
      placeholder: "Geräte suchen",
    },
    profileMenu: {
      signOut: "Abmelden",
    },
    filters: {
      all: "Alle",
      bracelet: "Notrufknopf",
      error: "Fehler",
    },
    navigationBar: {
      alarmCenters: "Notruf Zentralen",
      users: "Benutzer",
    },
    errors: {
      unknown: "Unbekannter Fehler",
    },
  },
};
