import { queryUsers, tenantRemoveUser, tenantUpdateUser, updateUser } from "@/api/users";

const updateTenantRole = (user, tenant, role) => {
  const existingTenantRole = user.tenants.find((tr) => tr.tenant.id === tenant.id);
  if (existingTenantRole) {
    existingTenantRole.role = role;
  } else {
    user.tenants.push({
      role,
      tenant,
    });
  }
};

const removeTenantRole = (user, tenantId) => {
  const index = user.tenants.findIndex((tr) => tr.tenant.id === tenantId);
  if (index > -1) {
    user.tenants.splice(index, 1);
  }
};

const state = () => ({
  all: new Map(),
  current: {},
});

const getters = {};

const actions = {
  async getUsers({ state, commit }) {
    if (state.all.size === 0) {
      const users = await queryUsers();
      commit("setUsers", users);
    }
  },
  async loadUser({ commit, state }, userId) {
    let user = state.all.get(userId);

    if (!user) {
      const users = await queryUsers(userId);
      user = users.length ? users[0] : null;
    }
    commit("setUser", user);
  },

  async updateUser({ state, commit }, user) {
    await updateUser(state.current.id, user.name);
    commit("updateUser", user);
  },
  async tenantUpdateUser({ state, commit }, { tenant, role }) {
    await tenantUpdateUser(state.current.id, tenant.id, role);
    commit("tenantUpdateUser", { tenant, role });
  },
  async tenantRemoveUser({ state, commit }, { tenantId }) {
    await tenantRemoveUser(state.current.id, tenantId);
    commit("tenantRemoveUser", tenantId);
  },
};

const mutations = {
  setUsers(state, users) {
    state.all.clear();
    users.forEach((user) => {
      state.all.set(user.id, user);
    });
  },
  setUser(state, user) {
    state.current = user;
  },
  updateUser(state, user) {
    const updatedUser = Object.assign({}, state.current, user);
    state.current = updatedUser;
    const listUser = state.all.get(state.current.id);
    if (listUser) {
      Object.assign(listUser, updatedUser);
    }
  },
  tenantUpdateUser(state, tenantRole) {
    updateTenantRole(state.current, tenantRole.tenant, tenantRole.role);
    const listUser = state.all.get(state.current.id);
    if (listUser) {
      updateTenantRole(listUser, tenantRole.tenant, tenantRole.role);
    }
  },
  tenantRemoveUser(state, tenantId) {
    removeTenantRole(state.current, tenantId);
    const listUser = state.all.get(state.current.id);
    if (listUser) {
      removeTenantRole(listUser, tenantId);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
