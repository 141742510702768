const statusOffline = ["bg-gray-100", "text-gray-800"];
const statusOk = ["bg-green-100", "text-green-800"];
const statusWarning = ["bg-yellow-100", "text-yellow-800"];
const statusError = ["bg-red-100", "text-red-800"];

const overallBraceletStatus = (device) => {
  if (!device.isOnline) {
    return statusOffline;
  }
  if (device.bracelets.every((bracelet) => bracelet.telemetry.lastState?.state === "OK")) {
    return statusOk;
  }
  return statusError;
};

const onlineStatus = (device) => {
  if (!device.isOnline) {
    return statusOffline;
  } else {
    return statusOk;
  }
};

const wifiStatus = (device) => {
  if (!device.isOnline || !device.telemetry.wifi) {
    return statusOffline;
  }
  if (device.telemetry.wifi.signalQuality === "GOOD") {
    return statusOk;
  }
  if (device.telemetry.wifi.signalQuality === "SUFFICIENT") {
    return statusWarning;
  }
  return statusError;
};

const modemStatus = (device) => {
  if (!device.isOnline || !device.telemetry.modem) {
    return statusOffline;
  }
  if (device.telemetry.modem.signalQuality === "GOOD") {
    return statusOk;
  }
  if (device.telemetry.modem.signalQuality === "SUFFICIENT") {
    return statusWarning;
  }
  return statusError;
};

const batteryStatus = (device) => {
  if (!device.isOnline || !device.telemetry.power) {
    return statusOffline;
  }

  const state = device.telemetry.power.batteryState;

  if (state === "FULLY_CHARGED") {
    return statusOk;
  }

  if (state === "RECHARGING" || state === "DRAINING") {
    return statusWarning;
  }
  return statusError;
};

export { overallBraceletStatus, wifiStatus, modemStatus, batteryStatus, onlineStatus };
