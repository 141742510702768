import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";

export default function render() {
  return (
    _openBlock(),
    _createBlock(
      "svg",
      {
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor",
        viewBox: "0 0 24 24",
        stroke: "none",
      },
      [
        _createVNode("path", {
          fill: "none",
          d: "M0 0h24v24H0V0z",
        }),
        _createVNode("path", {
          d: "M14.31 2l.41 2.48C13.87 4.17 12.96 4 12 4c-.95 0-1.87.17-2.71.47L9.7 2h4.61m.41 17.52L14.31 22H9.7l-.41-2.47c.84.3 1.76.47 2.71.47.96 0 1.87-.17 2.72-.48M16 0H8l-.95 5.73C5.19 7.19 4 9.45 4 12s1.19 4.81 3.05 6.27L8 24h8l.96-5.73C18.81 16.81 20 14.54 20 12s-1.19-4.81-3.04-6.27L16 0zm-4 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z",
        }),
      ]
    )
  );
}
