import { parseISO } from "date-fns";

const signalLevels = new Map();
signalLevels.set("UNKNOWN", 0);
signalLevels.set("INSUFFICIENT", 1);
signalLevels.set("SUFFICIENT", 2);
signalLevels.set("GOOD", 3);

export const signalTicks = new Map();
signalTicks.set(0, "Unknown");
signalTicks.set(1, "Insufficient");
signalTicks.set(2, "Sufficient");
signalTicks.set(3, "Good");

const signalColors = new Map();
signalColors.set(0, "rgb(156, 163, 175)");
signalColors.set(1, "rgb(248, 113, 113)");
signalColors.set(2, "rgb(245,158,11)");
signalColors.set(3, "rgb(5, 150, 105)");

const batteryState = new Map();
batteryState.set("EMPTY", 0);
batteryState.set("CRITICAL", 1);
batteryState.set("DRAINING", 2);
batteryState.set("RECHARGING", 3);
batteryState.set("FULLY_CHARGED", 4);

export const batteryTicks = new Map();
batteryTicks.set(0, "Empty");
batteryTicks.set(1, "Critical");
batteryTicks.set(2, "Draining");
batteryTicks.set(3, "Recharging");
batteryTicks.set(4, "Fully charged");

const batteryColors = new Map();
batteryColors.set(0, "rgb(156, 163, 175)");
batteryColors.set(1, "rgb(248, 113, 113)");
batteryColors.set(2, "rgb(245,158,11)");
batteryColors.set(3, "rgb(245,233,11)");
batteryColors.set(4, "rgb(5, 150, 105)");

export const connectionTicks = new Map();
connectionTicks.set(1, "Disconnected");
connectionTicks.set(2, "Connected");

export const signalColor = (data) => {
  return signalColors.get(data.parsed.y);
};

export const signalValue = (data) => {
  return signalLevels.get(data.signalQuality);
};

export const batteryValue = (data) => {
  return batteryState.get(data.batteryState);
};

export const batteryColor = (data) => {
  return batteryColors.get(data.parsed.y);
};

export const connectionValue = (data) => {
  return data.isConnected ? 2 : 1;
};

export const connectionColor = (data) => {
  if (data.parsed) {
    return data.parsed.y === 2 ? "rgb(5, 150, 105)" : "rgb(248, 113, 113)";
  }
  return 0;
};

export const timeSeriesByType = (history, typename, value) => {
  return history
    .map((node) => node.node)
    .filter((telemetry) => telemetry.__typename === typename)
    .map((telemetry) => ({
      x: parseISO(telemetry.timestamp),
      y: value(telemetry),
    }))
    .sort((a, b) => b.x - a.x)
    .reverse();
};

export const telemetryDataset = (data, type, value, color) => {
  if(data.length > 0){
    return {
      datasets: [
        {
          backgroundColor: color,
          data: timeSeriesByType(data, type, value),
        },
      ],
    };
  }
  return null
};
