<template>
  <div class="flex items-center h-16 flex-shrink-0 px-4">
    <img class="h-8 w-auto" src="../assets/logo.svg" alt="CARU" />
  </div>
  <div class="flex-1 flex flex-col overflow-y-auto">
    <nav class="flex-1 px-2 py-4 bg-gray-800 divide-y divide-gray-700">
      <div class="space-y-1">
        <router-link
          :to="{ name: 'devices', hash: quickFilter.hash }"
          :class="[
            isFilterActive(quickFilter.hash)
              ? 'bg-gray-900 text-white'
              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
          ]"
          v-for="quickFilter in quickFilters"
          v-bind:key="quickFilter.name"
        >
          <component :is="quickFilter.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
          {{ quickFilter.name }}
        </router-link>
      </div>
      <div class="mt-6 pt-6">
        <div class="space-y-1">
          <router-link
            :to="{ name: 'devices', hash: ownerFilter.hash }"
            :class="[
              isFilterActive(ownerFilter.hash)
                ? 'bg-gray-900 text-white'
                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
              'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
            ]"
            v-for="ownerFilter in ownerFilters"
            v-bind:key="ownerFilter.name"
          >
            <component :is="ownerFilter.icon" class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true" />
            {{ ownerFilter.name }}
          </router-link>
        </div>
      </div>
    </nav>
  </div>
  <div class="flex-shrink-0 flex border-t border-gray-400">
    <nav class="flex-1 px-2 py-4 bg-gray-800 divide-y divide-gray-500">
      <div class="space-y-1">
        <router-link
          :to="{ name: 'alarmcenters' }"
          exact-active-class="admin-links-active"
          class="
            text-gray-300
            hover:bg-gray-700 hover:text-white
            group
            flex
            items-center
            px-2
            py-2
            text-sm
            font-medium
            rounded-md
          "
        >
          <BellIcon class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true"></BellIcon>
          {{ $t("navigationBar.alarmCenters") }}
        </router-link>
        <router-link
          :to="{ name: 'users' }"
          exact-active-class="admin-links-active"
          class="
            text-gray-300
            hover:bg-gray-700 hover:text-white
            group
            flex
            items-center
            px-2
            py-2
            text-sm
            font-medium
            rounded-md
          "
        >
          <UserGroupIcon class="mr-4 flex-shrink-0 h-6 w-6 text-cyan-200" aria-hidden="true"></UserGroupIcon>
          {{ $t("navigationBar.users") }}
        </router-link>
      </div>
    </nav>
  </div>
</template>
<script>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { BellIcon, UserGroupIcon } from "@heroicons/vue/solid";
import { useRoute } from "vue-router";

export default {
  name: "Navigation",
  components: {
    BellIcon,
    UserGroupIcon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const quickFilters = computed(() => store.state.quickFilters);
    const ownerFilters = computed(() => store.state.ownerFilters);

    store.dispatch("createOwnerFilters");

    watch(
      () => route.hash,
      (toParams) => {
        store.dispatch("setFilter", toParams);
      }
    );

    return {
      quickFilters,
      ownerFilters,
      isFilterActive: store.getters.isFilterActive,
    };
  },
};
</script>
<style lang="scss">
.admin-links-active {
  color: white;
  background-color: rgba(17, 24, 39, 1);

  &:hover {
    background-color: rgba(17, 24, 39, 1) !important;
  }
}
</style>
