import { createApp } from "vue";

import App from "@/App.vue";
import store from "@/store";
import router from "@/router.js";
import i18n from "@/i18n";

import { Amplify } from "aws-amplify";
import awsConfig from "@/aws-exports.js";

import hljs from "highlight.js/lib/core";
import json from "highlight.js/lib/languages/json";
import hljsVuePlugin from "@highlightjs/vue-plugin";

hljs.registerLanguage("json", json);

import "./index.css";
import "highlight.js/styles/github.css";

Amplify.configure(awsConfig);

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(store);
app.use(hljsVuePlugin);
app.mount("#app");
