import { createI18n } from "vue-i18n";
import messages from "./messages";

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "de" || navigator.language.split("-")[0],
  fallbackLocale: "en",
  messages,
});
