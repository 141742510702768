<template>
  <table class="w-full">
    <tbody class="bg-gray-50 divide-y divide-gray-200">
      <tr v-for="row in 5" :key="row" class="animate-pulse">
        <td data-interactive class="px-4 py-2 w-12">
          <div class="h-4 bg-gray-400 rounded w-4"></div>
        </td>
        <td class="px-1 py-2 w-5">
          <div class="h-2.5 w-2.5 bg-gray-400 rounded-full"></div>
        </td>
        <td class="font-medium px-1 py-2 w-28">
          <div class="h-4 bg-gray-400 rounded w-3/4"></div>
        </td>
        <td class="px-1 py-2">
          <div class="h-4 bg-gray-400 rounded w-72 pr-12"></div>
        </td>
        <td class="px-1 py-2">
          <div class="h-4 bg-gray-400 rounded w-20"></div>
        </td>
        <td>
          <div class="h-4 w-14 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="h-6 w-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="h-6 w-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="h-6 w-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-8">
          <div class="h-6 w-6 bg-gray-400 rounded-full"></div>
        </td>
        <td class="w-32 pr-4">
          <div class="h-4 bg-gray-400 rounded w-full"></div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  name: "DeviceListPlaceholder",
};
</script>
