<template>
  <div class="m-5 mr-10" v-if="!editing">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Plugout Notifications</h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li
          class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
          v-for="destination in destinations"
          :key="destination.$uuid"
        >
          <div class="w-0 flex-1 flex items-center">
            <MailIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div class="ml-4 flex-shrink-0 font-light w-8">
              {{ destination.type.toUpperCase() }}
            </div>
            <span class="ml-4 flex-1 w-0 truncate"> {{ destination.destination }}</span>
          </div>
        </li>
      </ul>
    </dd>
    <button
      type="submit"
      @click="editing = true"
      class="
        mt-4
        inline-flex
        justify-center
        py-2
        px-4
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        disabled:opacity-50
      "
    >
      Edit
    </button>
  </div>
  <div class="m-5 mr-10" v-else>
    <notification-editor v-model="destinations"></notification-editor>
    <div class="mt-5 flex justify-end">
      <button
        type="button"
        class="
          bg-white
          py-2
          px-4
          border border-gray-300
          rounded-md
          shadow-sm
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="editing = false"
      >
        Cancel
      </button>
      <button
        type="submit"
        :disabled="notificationErrors"
        @click="editing = false"
        class="
          ml-4
          inline-flex
          justify-center
          py-2
          px-4
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          disabled:opacity-50
        "
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import NotificationEditor from "@/components/NotificationEditor";
import { MailIcon } from "@heroicons/vue/outline";
import { computed, ref } from "vue";

export default {
  name: "DeviceTabAlarm",
  components: {
    NotificationEditor,
    MailIcon,
  },
  props: ["deviceId"],
  setup() {
    const destinations = ref([
      { type: "sms", destination: "0041786439021" },
      { type: "email", destination: "station@soest.com" },
      { type: "email", destination: "urs@soest.com" },
      { type: "email", destination: "beat@soest.com" },
    ]);

    const notificationErrors = computed(
      () => !destinations.value.length || destinations.value.some((destination) => destination.$error !== "")
    );

    const editing = ref(false);

    return {
      editing,
      destinations,
      notificationErrors: notificationErrors,
    };
  },
};
</script>
