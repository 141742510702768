<template>
  <div class="pt-8 mx-auto px-4">
    <h1 class="flex-1 text-2xl font-bold text-gray-900">Alarm Centers</h1>
    <div class="flex flex-col">
      <div class="py-2 align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Tenant
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="arc in arcs" :key="arc.id">
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ arc.name }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800">
                    {{ arc.owner.name }}
                  </span>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <router-link
                    class="text-indigo-600 hover:text-indigo-900"
                    :to="{ name: 'arc', params: { arcId: arc.id } }"
                  >
                    Edit
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, onMounted } from "vue";

export default {
  setup() {
    const store = useStore();
    const arcs = computed(() => store.state.arc.all);

    onMounted(async () => {
      await store.dispatch("arc/getAlarmCenters");
    });

    return {
      arcs,
    };
  },
};
</script>
