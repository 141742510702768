<template>
  <div class="p-4">
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3 xl:grid-cols-7">
      <div :class="[...onlineStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">Serial Number</dt>
        <dd class="mt-1 text-lg font-semibold" v-if="device">
          {{ device.serialNumber }}
        </dd>
      </div>
      <div :class="[...batteryStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">Device Identity Status</dt>
        <dd class="mt-1 text-lg font-semibold">
          {{ device.state }}
        </dd>
      </div>
      <div :class="[...onlineStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">
          {{ device.isOnline ? "Last Hearthbeat" : "Last Hearthbeat (Offline)" }}
        </dt>
        <dd class="mt-1 text-lg font-bold">{{ dateFormat(device.lastHeartbeat) }}</dd>
      </div>
      <div :class="[...onlineStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">Firmware</dt>
        <dd class="mt-1 text-lg font-semibold" v-if="device.firmware">
          {{ device.firmware.version }}
        </dd>
        <dd class="mt-1 text-lg" v-else>UNKNOWN</dd>
      </div>
      <div :class="[...modemStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">Modem Status</dt>
        <dd class="mt-1 text-lg font-semibold" v-if="device.telemetry && device.telemetry.modem">
          {{ device.telemetry.modem.signalQuality.toLowerCase() }}
        </dd>
        <dd class="mt-1 text-lg" v-else>UNKNOWN</dd>
      </div>
      <div :class="[...wifiStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">WiFi Status</dt>
        <dd class="mt-1 text-lg font-semibold" v-if="device.telemetry && device.telemetry.wifi">
          {{ device.telemetry.wifi.signalQuality.toLowerCase() }}
        </dd>
        <dd class="mt-1 text-lg" v-else>UNKNOWN</dd>
      </div>
      <div :class="[...batteryStatus(device), 'rounded-lg', 'overflow', 'overflow-hidden', 'p-4']">
        <dt class="text-sm font-medium truncate">Battery Status</dt>
        <dd class="mt-1 text-lg font-semibold" v-if="device.telemetry && device.telemetry.power">
          {{ device.telemetry.power.batteryState.replace("_", " ").toLowerCase() }}
        </dd>
        <dd class="mt-1 text-lg" v-else>UNKNOWN</dd>
      </div>
    </dl>
  </div>

  <div class="flex flex-row px-4 pt-4">
    <div class="flex flex-col flex-1 pr-10 space-y-4">
      <TelemetryChart title="WiFi" :data="wifiData" :ticks="signalTicks" v-if="wifiData" />
      <TelemetryChart title="Modem" :data="modemData" :ticks="signalTicks" v-if="modemData" />
      <TelemetryChart title="Battery" :data="batteryData" :ticks="batteryTicks" v-if="batteryData" />
      <TelemetryChart title="Connection" :data="connectionData" :ticks="connectionTicks" v-if="connectionData" />
    </div>
    <div class="flex-1">
      <device-audit-log />
    </div>
  </div>
</template>
<script>
import DeviceAuditLog from "@/components/DeviceAuditLog";
import { useStore } from "vuex";

import { batteryStatus, modemStatus, onlineStatus, overallBraceletStatus, wifiStatus } from "@/lib/statusColors";

import TelemetryChart from "@/components/TelemetryChart";
import { computed } from "vue";
import {
  batteryColor,
  batteryTicks,
  batteryValue,
  connectionColor,
  connectionTicks,
  connectionValue,
  signalColor,
  signalTicks,
  signalValue,
  telemetryDataset,
} from "@/lib/timeseries";

export default {
  name: "DeviceTabGeneral",
  components: {
    TelemetryChart,
    DeviceAuditLog,
  },
  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const history = store.state.device.current.telemetry.history.edges;

    const wifiData = computed(() => telemetryDataset(history, "DeviceTelemetryWifi", signalValue, signalColor));
    const modemData = computed(() => telemetryDataset(history, "DeviceTelemetryModem", signalValue, signalColor));
    const batteryData = computed(() => telemetryDataset(history, "DeviceTelemetryPower", batteryValue, batteryColor));
    const connectionData = computed(() =>
      telemetryDataset(history, "DeviceTelemetryCloud", connectionValue, connectionColor)
    );

    return {
      device,
      onlineStatus,
      overallBraceletStatus,
      wifiStatus,
      modemStatus,
      batteryStatus,
      wifiData,
      modemData,
      batteryData,
      connectionData,
      signalTicks,
      batteryTicks,
      connectionTicks,
      dateFormat(date) {
        return new Date(date).toLocaleString("de-DE");
      },
    };
  },
};
</script>
