<template>
  <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Alert Destinations</h1>
  <div
    v-for="(alertDestination, index) in destinations"
    :key="alertDestination.$uuid"
    @keyup="validateDestinationAt(index)"
    @change="validateDestinationAt(index)"
  >
    <div class="flex">
      <select
        class="block focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        v-model="alertDestination.type"
        @change="alertDestination.destination = ''"
      >
        <option value="PSTN">PSTN</option>
        <option value="SIP">SIP</option>
        <option value="ARC">ARC</option>
      </select>
      <input
        v-if="alertDestination.type !== 'ARC'"
        v-model="alertDestination.destination"
        type="text"
        class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md ml-2"
      />
      <select
        v-else
        class="flex-1 block focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md ml-2"
        v-model="alertDestination.destination"
      >
        <option value="" disabled hidden>-- Select ARC --</option>
        <option v-for="arc in this.arcs" :value="arc.id" :key="arc.id">{{ arc.name }}</option>
      </select>
      <button class="flex items-center ml-1" @click="removeDestinationAt(index)">
        <MinusCircleIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
    </div>
    <div class="h-6 mt-1 mb-2">
      <div class="flex text-red-500 text-sm items-center" v-if="alertDestination.$error">
        <ExclamationCircleIcon class="h-5 w-5" aria-hidden="true" />
        <p class="ml-1">{{ alertDestination.$error }}</p>
      </div>
    </div>
  </div>

  <button
    @click="appendNewDestination"
    class="
      mt-2
      bg-white
      py-2
      w-full
      border border-gray-300
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-gray-700
      hover:bg-gray-50
    "
  >
    Add Destination
  </button>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ExclamationCircleIcon, MinusCircleIcon } from "@heroicons/vue/outline";
import { computed, reactive, watchEffect } from "vue";
import { useStore } from "vuex";

const isValidTelDestination = (destination) =>
  !isNaN(destination) && destination.startsWith("00") && destination.length > 6;

const isValidSipDestination = (destination) =>
  isNaN(destination) && destination.includes("@") && destination.includes(".");

export default {
  components: { MinusCircleIcon, ExclamationCircleIcon },
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const destinations = reactive(
      props.modelValue.map((item) => ({
        $uuid: uuidv4(),
        $error: "",
        ...item,
      }))
    );

    watchEffect(() => emit("update:modelValue", destinations));

    const store = useStore();
    let arcs = computed(() => store.state.arcs.all.map((arc) => ({ id: arc.id, name: arc.name })));

    // TODOD remove me, after arcs are handled on the arc configuration
    arcs = [
      {
        id: "11112222",
        name: "Medicall",
      },
      {
        id: "222222",
        name: "Skyresponse",
      },
      {
        id: "122222",
        name: "Enovation",
      },
    ];

    return {
      arcs,
      destinations,
      validateDestinationAt: (index) => {
        const { type, destination } = destinations[index];
        if (destination === "") {
          destinations[index].$error = "Destination cannot be empty.";
        } else if (type === "PSTN" && !isValidTelDestination(destination)) {
          destinations[index].$error = "Phone number not valid. Expected format: 004178021212";
        } else if (type === "SIP" && !isValidSipDestination(destination)) {
          destinations[index].$error = "SIP address not valid. Expected format: user@host.com";
        } else {
          destinations[index].$error = "";
        }
      },
      removeDestinationAt: (index) => {
        if (destinations.length > 1) {
          destinations.splice(index, 1);
        }
      },
      appendNewDestination: () => {
        destinations.push({
          type: "PSTN",
          destination: "",
          $error: "Destination cannot be empty.",
          $uuid: uuidv4(),
        });
      },
    };
  },
};
</script>
