<template>
  <TransitionRoot as="template" :show="open" class="z-20">
    <Dialog as="div" static class="fixed inset-0 overflow-hidden" @close="$emit('cancel')" :open="open">
      <div class="absolute inset-0 overflow-hidden">
        <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-md">
              <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
                <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
                  <div class="px-4 sm:px-6">
                    <div class="flex items-start justify-between">
                      <DialogTitle class="text-lg font-medium text-gray-900"> Copy Configuration</DialogTitle>
                    </div>
                  </div>
                  <div class="mt-6 relative flex-1 px-4 sm:px-6">
                    <Listbox as="div" v-model="selected">
                      <ListboxLabel class="block text-sm font-medium text-gray-700">Source Device</ListboxLabel>
                      <div class="mt-1 relative">
                        <ListboxButton
                          class="
                            bg-white
                            relative
                            w-full
                            border border-gray-300
                            rounded-md
                            shadow-sm
                            pl-3
                            pr-10
                            py-2
                            text-left
                            cursor-default
                            focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500
                            sm:text-sm
                          "
                        >
                          <span class="block truncate">{{ selected.serialNumber }}</span>
                          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </ListboxButton>

                        <transition
                          leave-active-class="transition ease-in duration-100"
                          leave-from-class="opacity-100"
                          leave-to-class="opacity-0"
                        >
                          <ListboxOptions
                            class="
                              absolute
                              z-10
                              mt-1
                              w-full
                              bg-white
                              shadow-lg
                              max-h-60
                              rounded-md
                              py-1
                              text-base
                              ring-1 ring-black ring-opacity-5
                              overflow-auto
                              focus:outline-none
                              sm:text-sm
                            "
                          >
                            <ListboxOption
                              as="template"
                              v-for="device in devices"
                              :key="device.id"
                              :value="device"
                              v-slot="{ active, selected }"
                            >
                              <li
                                :class="[
                                  active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                  'cursor-default select-none relative py-2 pl-3 pr-9',
                                ]"
                              >
                                <span :class="[selected ? 'font-semibold' : 'font-normal', 'block truncate']">
                                  {{ device.serialNumber }}
                                </span>

                                <span
                                  v-if="selected"
                                  :class="[
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                  ]"
                                >
                                  <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                              </li>
                            </ListboxOption>
                          </ListboxOptions>
                        </transition>
                      </div>
                    </Listbox>
                    <fieldset class="space-y-5">
                      <legend class="sr-only">Notifications</legend>
                      <div class="relative flex items-start">
                        <div class="flex items-center h-5">
                          <input
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            v-model="copyAlarmDestinations"
                            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div class="ml-3 text-sm">
                          <label for="comments" class="font-medium text-gray-700">Alarm Destinations</label>
                        </div>
                      </div>
                      <div>
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input
                              id="candidates"
                              aria-describedby="candidates-description"
                              name="candidates"
                              type="checkbox"
                              v-model="copyBracelets"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="candidates" class="font-medium text-gray-700">Bracelets</label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="relative flex items-start">
                          <div class="flex items-center h-5">
                            <input
                              id="offers"
                              aria-describedby="offers-description"
                              name="offers"
                              type="checkbox"
                              v-model="copyNotifications"
                              class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                            />
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="offers" class="font-medium text-gray-700">Notifications</label>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
                <div class="flex-shrink-0 px-4 py-4 flex justify-end">
                  <button
                    type="button"
                    class="
                      bg-white
                      py-2
                      px-4
                      border border-gray-300
                      rounded-md
                      shadow-sm
                      text-sm
                      font-medium
                      text-gray-700
                      hover:bg-gray-50
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                    "
                    @click="$emit('cancel')"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    :disabled="!valid"
                    class="
                      ml-4
                      inline-flex
                      justify-center
                      py-2
                      px-4
                      border border-transparent
                      shadow-sm
                      text-sm
                      font-medium
                      rounded-md
                      text-white
                      bg-indigo-600
                      hover:bg-indigo-700
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
                      disabled:opacity-50
                    "
                    @click="save()"
                  >
                    Copy Config
                  </button>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import {
  Dialog,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  Listbox,
  ListboxButton,
  ListboxLabel,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/vue";
import { ref, computed, onMounted } from "vue";
import { CheckIcon, SelectorIcon } from "@heroicons/vue/solid";
import { queryDeviceIds } from "@/api/device";

export default {
  components: {
    Dialog,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
  },
  name: "DeviceCopyConfigSlide",
  props: ["open"],
  emits: ["approve", "cancel"],
  setup() {
    const devices = ref([{ id: "default", serialNumber: "- Select Device -" }]);
    const selected = ref(devices.value[0]);

    const copyAlarmDestinations = ref(true);
    const copyBracelets = ref(true);
    const copyNotifications = ref(true);

    const getAllDevices = async () => {
      const deviceIds = await queryDeviceIds();
      devices.value = [...devices.value, ...deviceIds];
    };

    onMounted(getAllDevices);

    const valid = computed(
      () =>
        selected.value.id !== "default" &&
        (copyAlarmDestinations.value || copyBracelets.value || copyNotifications.value)
    );

    return {
      devices,
      selected,
      valid,
      copyAlarmDestinations,
      copyBracelets,
      copyNotifications,
      async save() {
        console.log(`Todo: Copy config from ${selected.value.serialNumber}`);
      },
    };
  },
};
</script>
