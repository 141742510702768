import {
  addBracelet,
  queryDevice,
  queryDevices,
  removeBracelet,
  updateAlarmAppDestinations,
  updateAlarmAppTriggers,
  updateGroup,
} from "@/api/device";
import { clearIndex, indexDevice } from "@/lib/search";

const state = () => ({
  all: new Map(),
  current: {},
  loading: false,
});

const actions = {
  async getAllDevices({ commit, state }, forceReload) {
    if (!forceReload && state.all.size) {
      return;
    }
    commit("setLoading", true);
    const devices = await queryDevices();
    commit("setDevices", devices);
    commit("setLoading", false);
  },

  async getDevice({ commit }, serialNumber) {
    commit("setLoading", true);

    const device = await queryDevice(serialNumber);

    commit("setDevice", device);
    commit("setLoading", false);
  },

  async reloadDevice({ commit }, serialNumber) {
    commit("setLoading", true);
    const device = await queryDevice(serialNumber);
    commit("setDevice", device);
    commit("setLoading", false);
  },

  async updateBracelets({ commit, state }, payload) {
    const { id: deviceId, bracelets } = payload;

    const newBraceletIds = bracelets.map((bracelet) => bracelet.id);
    const currentBraceletIds = state.current.bracelets.map((bracelet) => bracelet.id);

    const removed = currentBraceletIds.filter((id) => !newBraceletIds.includes(id));

    for (const braceletId of removed) {
      console.log("Removes bracelet to device:", deviceId, braceletId);
      await removeBracelet(deviceId, braceletId);
    }

    const added = newBraceletIds.filter((id) => !currentBraceletIds.includes(id));
    for (const braceletId of added) {
      console.log("Adds bracelet to device:", deviceId, braceletId);
      await addBracelet(deviceId, braceletId);
    }

    commit("setBracelets", bracelets);
  },

  async updateAlertDestinations({ commit, state }, alertDestinations) {
    await updateAlarmAppDestinations([state.current.id], alertDestinations);
    commit("setAlertDestinations", alertDestinations);
  },

  async updateAlertTriggers({ commit, state }, payload) {
    await updateAlarmAppTriggers([state.current.id], payload);
    commit("setAlertTriggers", payload);
  },

  async updateGroup({ commit, state }, groupName) {
    await updateGroup([state.current.id], groupName);
    commit("setGroup", groupName);
  },

  async batchUpdateGroup({ commit }, payload) {
    const { deviceIds, groupName } = payload;
    await updateGroup(deviceIds, groupName);
    commit("batchSetGroup", payload);
  },
};

const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },

  setDevices(state, devices) {
    state.all.clear();
    clearIndex();
    devices.forEach((device) => {
      state.all.set(device.id, device);
      indexDevice(device);
    });
  },

  setDevice(state, device) {
    state.current = { ...device };
  },

  setBracelets(state, bracelets) {
    state.current.bracelets = bracelets;
  },

  setAlertDestinations(state, alertDestinations) {
    state.current.apps.alarm.destinations = alertDestinations;
  },

  setAlertTriggers(state, triggers) {
    const { triggeredByVoice, triggeredByBracelet, triggeredByTouch } = triggers;
    state.current.apps.alarm.triggeredByVoice = triggeredByVoice;
    state.current.apps.alarm.triggeredByBracelet = triggeredByBracelet;
    state.current.apps.alarm.triggeredByTouch = triggeredByTouch;
  },

  setGroup(state, groupName) {
    state.current.group.name = groupName;
  },

  batchSetGroup(state, { deviceIds, groupName }) {
    deviceIds.forEach((deviceId) => {
      const device = state.all.get(deviceId);
      device.group.name = groupName;
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
