import { API } from "aws-amplify";
import arcQuery from "./graphql/arcs_query.graphql";

export const queryAlarmCenters = async () => {
  const result = await API.graphql({
    query: arcQuery,
  });
  const arcs = result ? result.data.apps.alarm.alarmReceivingCenters.edges.map((edge) => edge.node) : [];
  console.log(arcs)
  return arcs;
};
