<template>
  <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Bracelets</h1>

  <div
    v-for="(bracelet, index) in bracelets"
    :key="bracelet.$uuid"
    @keyup="validateBraceletAt(index)"
    @change="validateBraceletAt(index)"
  >
    <div class="flex">
      <input
        v-model="bracelet.id"
        type="text"
        class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md ml-2"
      />
      <button class="flex items-center ml-1" @click="removeBraceletAt(index)">
        <MinusCircleIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
    </div>
    <div class="h-6 mt-1 mb-2">
      <div class="flex text-red-500 text-sm items-center" v-if="bracelet.$error">
        <ExclamationCircleIcon class="h-5 w-5" aria-hidden="true" />
        <p class="ml-1">{{ bracelet.$error }}</p>
      </div>
    </div>
  </div>

  <button
    @click="appendNewBracelet"
    class="
      mt-2
      bg-white
      py-2
      w-full
      border border-gray-300
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-gray-700
      hover:bg-gray-50
    "
  >
    Add Bracelets
  </button>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ExclamationCircleIcon, MinusCircleIcon } from "@heroicons/vue/outline";
import { reactive, watch } from "vue";

export default {
  components: {
    MinusCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    modelValue: {
      default: [{ id: "001709219942" }, { id: "222125932322" }],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const bracelets = reactive(
      props.modelValue.map((item) => ({
        $uuid: uuidv4(),
        $error: "",
        ...item,
      }))
    );

    watch(
      () => bracelets,
      (bracelets) => emit("update:modelValue", bracelets),
      { deep: true }
    );

    return {
      bracelets,

      isValidBraceletId: function (braceletId) {
        return !isNaN(braceletId) && braceletId.length === 12;
      },

      validateBraceletAt: function (index) {
        const { id: braceletId } = this.bracelets[index];
        if (braceletId === "") {
          this.bracelets[index].$error = "Bracelet ID cannot be empty.";
        } else if (!this.isValidBraceletId(braceletId)) {
          this.bracelets[index].$error = "Bracelet ID is not valid: Must be 12 digits number. Example: 006569866354";
        } else {
          this.bracelets[index].$error = "";
        }
      },

      removeBraceletAt: (index) => {
        bracelets.splice(index, 1);
      },

      appendNewBracelet: () => {
        bracelets.push({ id: "", $error: "Bracelet ID cannot be empty.", $uuid: uuidv4() });
      },
    };
  },
};
</script>
