<template>
  <div class="w-0 flex-1 flex items-center">
    <CogIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
    <span class="ml-4 flex-1 w-0 truncate"> {{ $props.caption }}</span>
  </div>
  <div class="ml-4 flex-shrink-0">
    <Switch
      v-model="enabled"
      :class="[
        enabled ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
      ]"
    >
      <span class="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        :class="[
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
        ]"
      />
    </Switch>
  </div>
</template>
<script>
import { computed } from "vue";
import { CogIcon } from "@heroicons/vue/outline/esm";
import { Switch } from "@headlessui/vue";

export default {
  name: "Toggle",
  props: ["modelValue", "caption"],
  emits: ["update:modelValue"],
  components: {
    CogIcon,
    Switch,
  },

  setup(props, { emit }) {
    const enabled = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit("update:modelValue", value);
      },
    });

    return {
      enabled,
    };
  },
};
</script>
