import userQuery from "./graphql/users_query.graphql";
import userUpdate from "./graphql/user_update.graphql";
import tenantUpdateUserMutation from "./graphql/tenant_update_user.graphql";
import tenantRemoveUserMutation from "./graphql/tenant_remove_user.graphql";
import { runQuery } from "@/api/query";

const flattenUsers = (users) => {
  return users.edges.map((edge) => {
    let user = edge.node;
    user.tenants = user.tenants.edges.map((edge) => edge.node);
    return user;
  });
};

export const queryUsers = async (userIds) => {
  const result = await runQuery(userQuery, {
    userIds: userIds,
  });

  return result ? flattenUsers(result.users) : [];
};

export const updateUser = async (userId, name) => {
  const result = await runQuery(userUpdate, {
    userId: userId,
    name: name,
  });

  return result;
};

export const tenantUpdateUser = async (userId, tenantId, role) => {
  return await runQuery(tenantUpdateUserMutation, {
    userId,
    tenantId,
    role,
  });
};

export const tenantRemoveUser = async (userId, tenantId) => {
  return await runQuery(tenantRemoveUserMutation, {
    userId,
    tenantId,
  });
};
