<template>
  <div class="m-5 mr-10" v-if="!isEditing">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Bracelets</h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li
          class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
          v-for="bracelet in $store.state.device?.current?.bracelets"
          :key="bracelet.$uuid"
        >
          <div class="w-0 flex-1 flex items-center gap-2">
            <WatchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span class="flex-grow truncate"> {{ bracelet.serialNumber }}</span>
            <ChartBarIcon
              :class="['h-6', 'w-6', 'rounded-full', 'bg-green-100', 'text-green-800', 'px-1']"
              aria-hidden="true"
            />
            <LightningBoltIcon
              :class="['h-6', 'w-6', 'rounded-full', 'bg-green-100', 'text-green-800', 'px-1']"
              aria-hidden="true"
            />
          </div>
        </li>
      </ul>
    </dd>
    <button
      type="submit"
      @click="onEdit"
      class="
        mt-4
        inline-flex
        justify-center
        py-2
        px-4
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        disabled:opacity-50
      "
    >
      Edit
    </button>
  </div>
  <div class="m-5 mr-10" v-else>
    <bracelets-editor v-model="editedBracelets"></bracelets-editor>
    <div class="mt-5 flex justify-end">
      <button
        type="button"
        class="
          bg-white
          py-2
          px-4
          border border-gray-300
          rounded-md
          shadow-sm
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="isEditing = false"
      >
        Cancel
      </button>
      <button
        type="submit"
        :disabled="!isValidEdit"
        @click="onSave"
        class="
          ml-4
          inline-flex
          justify-center
          py-2
          px-4
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          disabled:opacity-50
        "
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import BraceletsEditor from "@/components/BraceletsEditor";
import Store from "@/store";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { WatchIcon } from "@/components/icons";
import { ChartBarIcon, LightningBoltIcon } from "@heroicons/vue/outline";
import { buildBraceletCrn } from "@/lib/crn";

export default {
  name: "DeviceTabBracelet",
  components: {
    BraceletsEditor,
    WatchIcon,
    ChartBarIcon,
    LightningBoltIcon,
  },
  props: ["deviceId"],
  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const isValidEdit = ref(true);
    const isEditing = ref(false);
    const editedBracelets = ref([]);

    watch(
      () => editedBracelets,
      (bracelets) => {
        isValidEdit.value = !bracelets.value.some((bracelet) => bracelet.$error !== "");
      },
      {
        deep: true,
      }
    );

    const onEdit = () => {
      isEditing.value = true;
      editedBracelets.value = device.value.bracelets.map((bracelet) => ({
        id: bracelet.serialNumber,
        $error: "",
      }));
    };

    const onSave = async () => {
      const tenantSlug = device.value.owner.slug;

      if (isValidEdit.value) {
        await Store.dispatch("device/updateBracelets", {
          id: device.value.id,
          bracelets: editedBracelets.value.map((bracelet) => ({
            id: buildBraceletCrn(tenantSlug, bracelet.id),
            serialNumber: bracelet.id,
          })),
        });

        isEditing.value = false;

        store.commit("showNotification", {
          title: "Successfully saved!",
          text: "The devices will receive the updated config as soon as they are online.",
          type: "info",
        });
      }
    };

    return {
      onEdit,
      onSave,
      isEditing,
      isValidEdit,
      editedBracelets,
      device,
    };
  },
};
</script>
