<template>
  <div class="border-b border-gray-200 px-4">
    <nav class="-mb-px flex space-x-8">
      <router-link
        :to="{ name: 'device' }"
        exact-active-class="device-tab-active"
        class="device-tab whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      >
        General
      </router-link>
      <router-link
        :to="{ name: 'deviceTabAlarm' }"
        exact-active-class="device-tab-active"
        class="device-tab whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      >
        Alert Destinations
      </router-link>
      <router-link
        :to="{ name: 'deviceTabBracelet' }"
        exact-active-class="device-tab-active"
        class="device-tab whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      >
        Bracelets
      </router-link>
      <router-link
        :to="{ name: 'deviceTabNotification' }"
        exact-active-class="device-tab-active"
        class="device-tab whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      >
        Notifications
      </router-link>
      <a
        class="
          border-transparent
          text-gray-500
          hover:text-gray-700 hover:border-gray-300
          whitespace-nowrap
          py-4
          px-1
          border-b-2
          font-medium
          text-sm
        "
      >
        WiFi
      </a>
      <router-link
        :to="{ name: 'deviceTabFeatures' }"
        exact-active-class="device-tab-active"
        class="device-tab whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      >
        Features
      </router-link>
    </nav>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  name: "DeviceSettingTabs",
  props: ["deviceId"],

  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    return {
      device,
    };
  },
};
</script>
<style lang="scss">
.device-tab {
  color: rgba(107, 114, 128, 1);
  border-color: transparent;

  &:hover {
    color: rgba(55, 65, 81, 1);
    border-color: rgba(209, 213, 219, 1);
  }

  &-active {
    color: rgba(79, 70, 229, 1);
    border-color: rgba(99, 102, 241, 1);

    &:hover {
      color: rgba(79, 70, 229, 1);
      border-color: rgba(99, 102, 241, 1);
    }
  }
}
</style>
