import Store from "@/store";
import { Auth } from "aws-amplify";

export const AuthFilter = (to, from, next) => {
  Auth.currentAuthenticatedUser({ bypassCache: true })
    .then((user) => {
      Store.commit("auth/setUser", user);
      next();
    })
    .catch(() => {
      Store.commit("auth/setUser", null);
      Auth.federatedSignIn();
    });
};
