<template>
  <ul class="mb-8">
    <li v-for="event in $store.state.device.current.events.edges.slice(0, 13)" :key="event.node.time">
      <div class="relative pb-4">
        <div class="relative flex space-x-3">
          <div>
            <span
              :class="[
                getIconBackground(event.node.type),
                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
              ]"
            >
              <component :is="getIcon(event.node.type)" class="h-5 w-5 text-white" aria-hidden="true" />
            </span>
          </div>
          <div class="w-full">
            <Disclosure v-slot="{ open }">
              <DisclosureButton
                class="
                  flex
                  justify-between
                  w-full
                  px-4
                  py-2
                  text-sm
                  font-medium
                  text-left text-gray-900
                  bg-gray-100
                  rounded-lg
                  hover:bg-gray-200
                  focus:outline-non
                  e
                  focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75
                "
              >
                <span
                  ><b>{{ dateFormat(event.node.time) }}: </b> {{ event.node.name }}</span
                >
                <ChevronUpIcon :class="open ? 'transform rotate-180' : ''" class="w-5 h-5 text-gray-500" />
              </DisclosureButton>
              <DisclosurePanel class="text-sm text-gray-500">
                <highlightjs language="json" :code="formatJson(event.node.data)" />
              </DisclosurePanel>
            </Disclosure>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { CheckIcon, ChevronUpIcon } from "@heroicons/vue/solid";

export default {
  name: "DeviceAuditLog",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronUpIcon,
    CheckIcon,
  },

  setup() {
    return {
      getIconBackground() {
        return "bg-green-500";
      },
      getIcon() {
        return CheckIcon;
      },
      dateFormat(date) {
        return new Date(date).toLocaleString("de-DE");
      },
      formatJson(data) {
        return JSON.stringify(JSON.parse(data), null, 2);
      },
    };
  },
};
</script>
