import { createLogger, createStore } from "vuex";
import users from "./modules/users";
import device from "./modules/device";
import arc from "./modules/arc";
import { parseQuery, quickFilters, ownerFilter } from "@/lib/search";
import { useRoute } from "vue-router";
import { queryTenants } from "@/api/tenants";
import auth from "@/store/modules/auth";

const debug = process.env.NODE_ENV !== "production";

const notificationTimeout = 10; //seconds

const state = () => ({
  searchQueryData: { text: "" },
  searchQuery: "",
  quickFilters,
  ownerFilters: [],
  notification: null,
  tenants: null,
});

const getters = {
  queryByHash: (state) => (hash) => {
    let filterDefinition = state.quickFilters.find((filter) => filter.hash === hash);
    if (!filterDefinition) {
      filterDefinition = state.ownerFilters.find((filter) => filter.hash === hash);
    }
    return filterDefinition ? filterDefinition.query : null;
  },
  isFilterActive: (state, getters) => (hash) => {
    const route = useRoute();
    if (route.name !== "devices") return false;
    const query = getters.queryByHash(hash);
    const filterSearchData = parseQuery(query);
    return JSON.stringify(filterSearchData) === JSON.stringify(state.searchQueryData);
  },
};

const actions = {
  async search({ commit }, searchQuery) {
    commit("setSearchQuery", searchQuery);
    commit("setSearchQueryData", parseQuery(searchQuery));
  },
  async setFilter({ commit, getters }, filterQuery) {
    const query = getters.queryByHash(filterQuery);
    commit("setSearchQueryData", parseQuery(query));
    commit("setSearchQuery", query ? query + " " : null);
  },
  async loadTenants({ commit, state }) {
    if (!state.tenants) {
      const tenants = await queryTenants();
      commit("setTenants", tenants);
    }
  },
  async createOwnerFilters({ commit, dispatch, state }) {
    await dispatch("loadTenants");
    commit(
      "setOwnerFilters",
      state.tenants.map((tenant) => ownerFilter(tenant))
    );
  },
  async showNotification({ commit }, notification) {
    commit("clearNotification", notification);

    notification.timeoutId = setTimeout(() => {
      commit("clearNotification");
    }, notificationTimeout * 1000);

    commit("showNotification", notification);
  },
};

const mutations = {
  setTenants(state, tenants) {
    state.tenants = tenants;
  },
  setSearchQueryData(state, searchQueryData) {
    state.searchQueryData = searchQueryData;
  },
  setSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  setOwnerFilters(state, ownerFilters) {
    state.ownerFilters = ownerFilters;
  },
  showNotification(state, notification) {
    state.notification = notification;
  },
  clearNotification(state) {
    if (state.notification && state.notification.timeoutId) {
      clearInterval(state.notification.timeoutId);
    }
    state.notification = null;
  },
};

export default createStore({
  modules: {
    users,
    device,
    arc,
    auth,
  },
  state,
  getters,
  actions,
  mutations,
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
