<template>
  <div class="py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
    <h1 class="text-3xl font-extrabold text-blue-gray-900">Account</h1>

    <form class="mt-6" v-on:submit.prevent="save">
      <div class="sm:col-span-3 mb-10">
        <label for="name" class="block text-sm font-medium text-blue-gray-900">Name</label>
        <input
          type="text"
          name="name"
          id="name"
          autocomplete="name"
          v-model="userName"
          class="
            mt-1
            block
            w-full
            border-blue-gray-300
            rounded-md
            shadow-sm
            text-blue-gray-900
            sm:text-sm
            focus:ring-blue-500 focus:border-blue-500
          "
        />
      </div>

      <h3 class="font-medium text-gray-900">Permissions</h3>

      <div>
        <div
          v-for="tenantRole in tenantRoles"
          :key="tenantRole.tenant.id"
          class="grid grid-cols-3 gap-4 items-center pt-2"
        >
          <label>{{ tenantRole.tenant.name }}</label>
          <select
            id="location"
            name="location"
            v-model="tenantRole.role"
            class="
              mt-1
              block
              col-span-2
              text-base
              border-gray-300
              focus:outline-none focus:ring-indigo-500 focus:border-indigo-500
              rounded-md
            "
          >
            <option :value="null">No Access</option>
            <option value="VIEWER">Viewer</option>
            <option value="ADMIN">Admin</option>
          </select>
        </div>
      </div>

      <div class="pt-8 flex justify-end">
        <router-link
          :to="{ name: 'users' }"
          type="button"
          class="
            bg-white
            py-2
            px-4
            border border-gray-300
            rounded-md
            shadow-sm
            text-sm
            font-medium
            text-blue-gray-900
            hover:bg-blue-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          "
        >
          Cancel
        </router-link>
        <button
          type="submit"
          class="
            ml-3
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-blue-600
            hover:bg-blue-700
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500
          "
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { useRouter } from "vue-router";

export default {
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const userName = ref(store.state.users.current.name);

    const tenantRoles = ref(
      store.state.tenants.map((tenant) => {
        const tenantRole = { tenant: tenant, role: null };
        const attachedRole = store.state.users.current.tenants.find((tenantRole) => tenantRole.tenant.id === tenant.id);
        if (attachedRole) {
          tenantRole.role = attachedRole.role;
        }
        return tenantRole;
      })
    );

    return {
      userName,
      tenantRoles: tenantRoles,
      async save() {
        await store.dispatch("users/updateUser", { name: userName.value });
        for (const tenantRole of tenantRoles.value) {
          if (tenantRole.role) {
            await store.dispatch("users/tenantUpdateUser", {
              tenant: tenantRole.tenant,
              role: tenantRole.role,
            });
          } else {
            await store.dispatch("users/tenantRemoveUser", { tenantId: tenantRole.tenant.id });
          }
        }
        await router.push({ name: "users" });
      },
    };
  },
};
</script>
