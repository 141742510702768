<template>
  <div class="m-5 mr-10">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Feature Flags</h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <Toggle caption="Enable Family Chat" v-model="familyChat" />
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <Toggle caption="Enable Voice Activity Detection" v-model="voiceActivityDetection" />
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <Toggle caption="Enable Malfunction Indication" v-model="malfunctionIndication" />
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <Toggle caption="Enable Keyword Recording Upload" v-model="keywordRecordingUpload" />
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <Toggle caption="Enable LEDs" v-model="showLEDs" />
        </li>
      </ul>
    </dd>
    <h1 class="text-lg leading-6 font-medium text-gray-900 mt-5 mb-5">Miscellaneous</h1>
    <dt class="text-sm font-medium text-gray-500">Internal Settings</dt>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div class="w-0 flex-1 flex items-center">
            <span class="ml-2 flex-1 w-0 truncate"> Audio: Sound Volume </span>
          </div>
          <div class="ml-4 flex-shrink-0">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Edit </a>
          </div>
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div class="w-0 flex-1 flex items-center">
            <span class="ml-2 flex-1 w-0 truncate"> Classifier: Thresholds </span>
          </div>
          <div class="ml-4 flex-shrink-0">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Edit </a>
          </div>
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <div class="w-0 flex-1 flex items-center">
            <span class="ml-2 flex-1 w-0 truncate"> Cortex: Log Level</span>
          </div>
          <div class="ml-4 flex-shrink-0">
            <a href="#" class="font-medium text-indigo-600 hover:text-indigo-500"> Edit </a>
          </div>
        </li>
      </ul>
    </dd>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import Toggle from "@/components/Toggle";
import { useStore } from "vuex";

export default {
  name: "DeviceTabBracelet",
  components: {
    Toggle,
  },
  props: ["deviceId"],
  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const { development, experiments } = device.value.config;
    const keywordRecordingUpload = ref(development.keywordRecordingUpdload);
    const showLEDs = ref(development.showLEDs);
    const familyChat = ref(experiments.familyChat);
    const voiceActivityDetection = ref(experiments.voiceActivityDetection);
    const malfunctionIndication = ref(experiments.malfunctionIndication);

    watch([keywordRecordingUpload, showLEDs, familyChat, voiceActivityDetection, malfunctionIndication], () =>
      console.log("TODO make API call")
    );

    return {
      keywordRecordingUpload,
      showLEDs,
      familyChat,
      voiceActivityDetection,
      malfunctionIndication,
    };
  },
};
</script>
