<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        static
        class="fixed inset-0 flex z-40 lg:hidden"
        @close="sidebarOpen = false"
        :open="sidebarOpen"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">Close sidebar</span>
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <Navigation />
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-64">
        <div class="flex flex-col h-0 flex-1 bg-gray-800">
          <Navigation />
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-10 flex-shrink-0 bg-white">
        <div class="flex h-16 border-b border-gray-200">
          <button
            type="button"
            class="
              px-4
              border-r border-gray-200
              text-gray-500
              focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900
              lg:hidden
            "
            @click="sidebarOpen = true"
          >
            <span class="sr-only">Open sidebar</span>
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 px-4 flex justify-between items-center">
            <div class="flex-1 flex">
              <form class="w-full flex lg:ml-0 items-center" v-on:submit.prevent="search">
                <label for="search_field" class="sr-only">{{ $t("search.placeholder") }}</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                  <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                    <SearchIcon class="h-5 w-5" aria-hidden="true" />
                  </div>
                  <input
                    id="search_field"
                    class="
                      block
                      w-full
                      h-full
                      pl-8
                      pr-3
                      py-2
                      border-transparent
                      text-gray-900
                      placeholder-gray-500
                      focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent
                      sm:text-sm
                    "
                    :placeholder="$t('search.placeholder')"
                    type="text"
                    name="search"
                    v-model="searchQuery"
                  />
                </div>
                <button
                  type="button"
                  @click="clearSearch"
                  class="
                    -ml-px
                    relative
                    inline-flex
                    items-center
                    h-5
                    w-5
                    rounded-r-md
                    text-gray-700
                    bg-transparent
                    hover:bg-gray-50
                    focus:outline-none
                  "
                >
                  <XIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </form>
            </div>
            <Menu as="div" class="ml-12 relative flex-shrink-0">
              <div>
                <MenuButton class="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                  <span class="text-sm font-medium leading-none text-white">{{ userName }}</span>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <MenuItems
                  class="
                    origin-top-right
                    absolute
                    right-0
                    mt-2
                    w-48
                    rounded-md
                    shadow-lg
                    py-1
                    bg-white
                    ring-1 ring-black ring-opacity-5
                    focus:outline-none
                  "
                >
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[active ? 'bg-gray-100' : '', 'w-full text-left block px-4 py-2 text-sm text-gray-700']"
                      @click="signOut"
                    >
                      {{ $t("profileMenu.signOut") }}
                    </button>
                  </MenuItem>
                </MenuItems>
              </transition>
            </Menu>
          </div>
        </div>
        <div id="tools"></div>
      </div>

      <main class="flex-1 relative overflow-y-auto focus:outline-none">
        <router-view></router-view>
      </main>
    </div>
  </div>
  <Notification />
</template>

<script>
import { computed, ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { MenuAlt2Icon, SearchIcon, XIcon } from "@heroicons/vue/solid";
import { useStore } from "vuex";
import Navigation from "./components/Navigation.vue";
import { Auth } from "aws-amplify";
import Notification from "@/Notification";
import { useRouter } from "vue-router";

export default {
  components: {
    Notification,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Navigation,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    SearchIcon,
    MenuAlt2Icon,
    XIcon,
  },
  setup() {
    const sidebarOpen = ref(false);
    const store = useStore();
    const router = useRouter();
    const currentQuery = ref("");
    const searchQuery = computed({
      get: () => store.state.searchQuery,
      set: (term) => (currentQuery.value = term),
    });

    const userName = computed(() => {
      const user = store.state.auth.user;
      if (user && user.attributes.name) {
        return user.attributes.name
          .split(" ")
          .slice(0, 2)
          .reduce((a, v) => a + (v.length ? v[0] : ""), "");
      }
      return "?";
    });

    const clearSearch = async () => {
      await router.push({ name: "devices" });
      await store.dispatch("search", "");
    };

    const search = async () => {
      await router.push({ name: "devices" });
      await store.dispatch("search", currentQuery.value);
    };

    return {
      sidebarOpen,
      searchQuery,
      clearSearch,
      search,
      userName,
      async signOut() {
        await Auth.signOut({ global: true });
      },
    };
  },
};
</script>
