import { API } from "aws-amplify";
import deviceQuery from "./graphql/device_query.graphql";
import { runQuery } from "@/api/query";
import braceletRemove from "@/api/graphql/bracelet_remove.graphql";
import braceletAdd from "@/api/graphql/bracelet_add.graphql";
import appsAlarmUpdateDestinations from "@/api/graphql/apps_alarm_update_destinations.graphql";
import appsAlarmUpdateTriggers from "@/api/graphql/apps_alarm_update_triggers.graphql";
import devicesQuery from "@/api/graphql/devices_query.graphql";
import devicesIdsQuery from "@/api/graphql/devices_ids_query.graphql";

export const queryDevices = async () => {
  const result = await runQuery(devicesQuery);
  return result ? result.devices.edges.map((edge) => edge.node) : [];
};

export const queryDevice = async (serialNumber) => {
  const result = await API.graphql({
    query: deviceQuery,
    variables: { serialNumber },
  });
  return result.data.devices.edges[0].node;
};

export const queryDeviceIds = async () => {
  const result = await runQuery(devicesIdsQuery);
  return result ? result.devices.edges.map((edge) => edge.node) : [];
};

export const addBracelet = async (deviceId, braceletId) => {
  return await runQuery(braceletAdd, {
    deviceId,
    braceletId,
  });
};

export const removeBracelet = async (deviceId, braceletId) => {
  return await runQuery(braceletRemove, {
    deviceId,
    braceletId,
  });
};

export const updateAlarmAppDestinations = async (deviceIds, destinations) => {
  return await runQuery(appsAlarmUpdateDestinations, {
    deviceIds,
    destinations,
  });
};

export const updateAlarmAppTriggers = async (deviceIds, triggers) => {
  return await runQuery(appsAlarmUpdateTriggers, {
    deviceIds,
    ...triggers,
  });
};

export const updateNotifications = async (deviceIds, notifications) => {
  console.log(`Todo: Update devices ${deviceIds} notifications: ${notifications}`);
};

export const updateGroup = async (deviceIds, groupName) => {
  console.log(`Todo: Update devices ${deviceIds} group: ${groupName}`);
};

export const diagnoseDevice = async (deviceIds) => {
  console.log("TODO: Would run diagnose for: ", deviceIds);
};

export const resetDevice = async (deviceIds) => {
  console.log("TODO: Would reset: ", deviceIds);
};

export const rebootDevice = async (deviceIds) => {
  console.log("TODO: Would reboot: ", deviceIds);
};

export const shutdownDevice = async (deviceIds) => {
  console.log("TODO: Would shutdown: ", deviceIds);
};

export const deactivateDevice = async (deviceIds) => {
  console.log("TODO: Would deactivate: ", deviceIds);
};
