import {
  ExclamationCircleIcon,
  HomeIcon,
  StatusOfflineIcon,
  StatusOnlineIcon,
  TagIcon,
  UserIcon,
} from "@heroicons/vue/solid";
import MiniSearch from "minisearch";
import * as searchQuery from "search-query-parser";
import i18n from "@/i18n";

const searchEngine = new MiniSearch({
  fields: ["serialNumber", "label", "bracelets"],
});

export const indexDevice = (device) => {
  searchEngine.add({
    id: device.id,
    serialNumber: device.serialNumber,
    label: device.label,
    bracelets: device.bracelets.map((bracelet) => bracelet.serialNumber).join(" "),
  });
};

export const clearIndex = () => {
  searchEngine.removeAll();
};

export const filterKeywords = ["is", "has", "status", "owner"];

export const quickFilters = [
  {
    name: i18n.global.t("filters.all"),
    hash: "#all",
    query: null,
    icon: HomeIcon,
  },
  {
    name: "Offline",
    hash: "#offline",
    query: "is:offline",
    icon: StatusOfflineIcon,
  },
  {
    name: "Online",
    hash: "#online",
    query: "is:online",
    icon: StatusOnlineIcon,
  },
  {
    name: i18n.global.t("filters.bracelet"),
    hash: "#bracelet",
    query: "has:bracelet",
    icon: TagIcon,
  },
  {
    name: i18n.global.t("filters.error"),
    hash: "#error",
    query: "status:error",
    icon: ExclamationCircleIcon,
  },
];

export const ownerFilter = (owner) => {
  return {
    name: owner.name,
    hash: `#owner/${owner.name}`,
    query: `owner:"${owner.name}"`,
    icon: UserIcon,
  };
};

const fieldFilters = new Map();

fieldFilters.set("is", (connection) => {
  if (connection === "online") {
    return (device) => device.isOnline;
  } else if (connection === "offline") {
    return (device) => !device.isOnline;
  }
  return () => true;
});

fieldFilters.set("owner", (ownerName) => (device) => device.owner.name === ownerName);

fieldFilters.set("status", () => (device) => device.hasError);

fieldFilters.set("has", (attribute) => {
  if (attribute === "bracelet") {
    return (device) => device.bracelets.length > 0;
  }
  return () => true;
});

/*
Normalize query data to always be an object containing the "text" property
 */
export const parseQuery = (query) => {
  const queryData = searchQuery.parse(query, { keywords: filterKeywords });
  if (typeof queryData === "string") {
    return { text: queryData };
  }
  if (!("text" in queryData)) {
    queryData.text = "";
  }
  return queryData;
};

const textSearch = (query, devices) => {
  if (query.length < 1) {
    return Array.from(devices.values());
  }
  const results = searchEngine.search(query, { prefix: true });
  return results.map((r) => devices.get(r.id));
};

export const searchDevices = (queryData, devices) => {
  let resultDevices = textSearch(queryData.text, devices);

  fieldFilters.forEach(function (filterGenerator, key) {
    if (key in queryData) {
      resultDevices = resultDevices.filter(filterGenerator(queryData[key]));
    }
  });

  return resultDevices;
};
