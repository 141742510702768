<template>
  <td class="px-1 py-2 w-5">
    <div
      :class="[device.isOnline ? 'bg-green-500' : 'bg-red-500', 'w-2.5', 'h-2.5', 'rounded-full']"
      aria-hidden="true"
    />
  </td>
  <td class="font-medium px-1 py-2 w-28">
    {{ device.serialNumber }}
  </td>
  <td class="px-1 py-2">
    {{ device.label }}
  </td>
  <td class="px-1 py-2 w-40">
    <div
      v-if="device.group"
      class="
        bg-gray-600
        inline-flex
        items-baseline
        px-2
        py-0.5
        rounded-full
        font-light
        text-xs text-white
        whitespace-nowrap
      "
    >
      {{ device.group.name }}
    </div>
  </td>
  <td class="px-1 py-2 font-light truncate">
    {{ device.owner.name }}
  </td>
  <td class="w-8">
    <WatchIcon
      v-if="device.bracelets.length"
      :class="['h-6', 'w-6', 'rounded-full', ...overallBraceletStatus(device), 'px-1']"
      aria-hidden="true"
    />
  </td>
  <td class="w-8">
    <WifiIcon :class="['h-6', 'w-6', 'rounded-full', ...wifiStatus(device), 'px-1']" aria-hidden="true" />
  </td>
  <td class="w-8">
    <ChartBarIcon :class="['h-6', 'w-6', 'rounded-full', ...modemStatus(device), 'px-1']" aria-hidden="true" />
  </td>
  <td class="w-8">
    <BatteryIcon :class="['h-6', 'w-6', 'rounded-full', ...batteryStatus(device), 'px-1']" aria-hidden="true" />
  </td>
  <td class="font-light italic text-xs text-right w-36 pr-4">{{ dateFormat(device.lastHeartbeat) }}</td>
</template>

<script>
import { ChartBarIcon, WifiIcon } from "@heroicons/vue/outline";
import { WatchIcon, BatteryIcon } from "@/components/icons";
import { overallBraceletStatus, wifiStatus, modemStatus, batteryStatus } from "@/lib/statusColors";

export default {
  components: {
    WifiIcon,
    ChartBarIcon,
    WatchIcon,
    BatteryIcon,
  },
  name: "DeviceListItem",
  props: {
    device: { required: true },
  },
  setup() {
    return {
      overallBraceletStatus,
      wifiStatus,
      modemStatus,
      batteryStatus,
      dateFormat(date) {
        return new Date(date).toLocaleString("de-DE");
      },
    };
  },
};
</script>
