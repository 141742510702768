<template>
  <div class="m-5 mr-10" v-if="!isEditing">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Alert Triggers</h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <AlertTrigger v-model="triggeredByVoice" caption="Trigger by Voice"></AlertTrigger>
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <AlertTrigger v-model="triggeredByTouch" caption="Trigger by Touch"></AlertTrigger>
        </li>
        <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
          <AlertTrigger v-model="triggeredByBracelet" caption="Trigger by Bracelet"></AlertTrigger>
        </li>
      </ul>
    </dd>
  </div>
  <div class="m-5 mr-10" v-if="!isEditing">
    <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Alert Destinations</h1>
    <dd class="mt-1 text-sm text-gray-900">
      <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
        <li
          class="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
          v-for="alertDestination in device.apps.alarm.destinations"
          :key="alertDestination.$uuid"
        >
          <div class="w-0 flex-1 flex items-center">
            <PhoneOutgoingIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div class="ml-4 flex-shrink-0 font-light w-6">
              {{ alertDestination.type.toUpperCase() }}
            </div>
            <span class="ml-4 flex-1 w-0 truncate"> {{ alertDestination.destination }}</span>
          </div>
        </li>
      </ul>
    </dd>
    <button
      type="submit"
      @click="onEdit"
      class="
        mt-4
        inline-flex
        justify-center
        py-2
        px-4
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        disabled:opacity-50
      "
    >
      Edit
    </button>
    <i class="ml-4 text-xs" v-if="device.apps.alarm.destinations.length === 0">No alert destinations configured</i>
  </div>
  <div class="m-5 mr-10" v-else>
    <alert-destinations-editor v-model="editedAlertDestinations"></alert-destinations-editor>
    <div class="mt-5 flex justify-end">
      <button
        type="button"
        class="
          bg-white
          py-2
          px-4
          border border-gray-300
          rounded-md
          shadow-sm
          text-sm
          font-medium
          text-gray-700
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="isEditing = false"
      >
        Cancel
      </button>
      <button
        type="submit"
        :disabled="!isValidEdit"
        @click="onSave"
        class="
          ml-4
          inline-flex
          justify-center
          py-2
          px-4
          border border-transparent
          shadow-sm
          text-sm
          font-medium
          rounded-md
          text-white
          bg-indigo-600
          hover:bg-indigo-700
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          disabled:opacity-50
        "
      >
        Save
      </button>
    </div>
  </div>
</template>
<script>
import AlertDestinationsEditor from "@/components/AlertDestinationsEditor";
import { PhoneOutgoingIcon } from "@heroicons/vue/outline";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import Store from "@/store";
import AlertTrigger from "@/components/Toggle";

export default {
  name: "DeviceTabAlarm",
  components: {
    AlertTrigger,
    AlertDestinationsEditor,
    PhoneOutgoingIcon,
  },
  setup() {
    const store = useStore();
    const device = computed(() => store.state.device.current);

    const isEditing = ref(false);
    const editedAlertDestinations = ref([]);

    const { alarm } = device.value.apps;
    const triggeredByVoice = ref(alarm.triggeredByVoice);
    const triggeredByTouch = ref(alarm.triggeredByTouch);
    const triggeredByBracelet = ref(alarm.triggeredByBracelet);

    const isValidEdit = computed(() => !editedAlertDestinations.value.some((destination) => destination.$error !== ""));

    watch([triggeredByVoice, triggeredByTouch, triggeredByBracelet], () => {
      Store.dispatch("device/updateAlertTriggers", {
        triggeredByVoice: triggeredByVoice.value,
        triggeredByTouch: triggeredByTouch.value,
        triggeredByBracelet: triggeredByBracelet.value,
      });
    });

    const onEdit = () => {
      isEditing.value = true;
      editedAlertDestinations.value = device.value.apps.alarm.destinations.map((destination) => ({
        type: destination.type,
        destination: destination.destination,
        $error: "",
      }));
    };

    const onSave = async () => {
      if (isValidEdit.value) {
        await Store.dispatch(
          "device/updateAlertDestinations",
          editedAlertDestinations.value.map((destination) => ({
            type: destination.type,
            destination: destination.destination,
          }))
        );

        isEditing.value = false;

        store.commit("showNotification", {
          title: "Successfully saved!",
          text: "The devices will receive the updated config as soon as they are online.",
          type: "info",
        });
      }
    };

    return {
      triggeredByVoice,
      triggeredByTouch,
      triggeredByBracelet,
      onEdit,
      onSave,
      isEditing,
      isValidEdit,
      editedAlertDestinations,
      device,
    };
  },
};
</script>
