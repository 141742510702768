<template>
  <div class="min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll">
    <div class="relative flex-1 px-4 sm:px-6">
      <AlertDestinationsEditor v-model="alertDestinations" />
    </div>
  </div>
  <div class="flex-shrink-0 px-4 py-4 flex justify-end">
    <button
      type="button"
      class="
        bg-white
        py-2
        px-4
        border border-gray-300
        rounded-md
        shadow-sm
        text-sm
        font-medium
        text-gray-700
        hover:bg-gray-50
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
      "
      @click="$emit('close')"
    >
      Cancel
    </button>
    <button
      type="submit"
      :disabled="alertDestinationsErrors"
      @click="alertOpen = true"
      class="
        ml-4
        inline-flex
        justify-center
        py-2
        px-4
        border border-transparent
        shadow-sm
        text-sm
        font-medium
        rounded-md
        text-white
        bg-indigo-600
        hover:bg-indigo-700
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        disabled:opacity-50
      "
    >
      Save
    </button>
  </div>
  <SimpleAlert
    @cancel="alertOpen = false"
    @approve="approve"
    :open="alertOpen"
    title="Apply alarm configuration?"
    description="Are you sure you want to apply this alarm configuration to all selected devices? All existing configurations will be overwritten. This action cannot be undone."
    action="Apply"
  ></SimpleAlert>
</template>

<script>
import AlertDestinationsEditor from "@/components/AlertDestinationsEditor";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import SimpleAlert from "@/components/modals/Alert";
import { updateAlarmAppDestinations } from "@/api/device";

export default {
  components: {
    SimpleAlert,
    AlertDestinationsEditor,
  },
  emits: ["close"],
  props: ["deviceSelection"],

  setup(props, { emit }) {
    const store = useStore();
    const alertOpen = ref(false);
    const alertDestinations = ref([]);
    const alertDestinationsErrors = computed(
      () => !alertDestinations.value.length || alertDestinations.value.some((destination) => destination.$error !== "")
    );

    return {
      alertOpen,
      alertDestinations,
      alertDestinationsErrors,
      async approve() {
        alertOpen.value = false;

        await updateAlarmAppDestinations(
          props.deviceSelection,

          alertDestinations.value.map((destination) => ({
            type: destination.type,
            destination: destination.destination,
          }))
        );

        store.dispatch("showNotification", {
          title: "Successfully saved!",
          text: "The devices will receive the updated config as soon as they are online.",
          type: "info",
        });
        emit("close");
      },
    };
  },
};
</script>
