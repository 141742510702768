<template>
  <div class="border border-gray-100 rounded-lg">
    <div class="px-4 py-2">
      <h3 class="text-lg font-medium text-gray-900">{{ title }}</h3>
    </div>
    <div class="bg-gray-50 px-4 py-5 sm:p-6">
      <canvas ref="canvas"></canvas>
    </div>
  </div>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import Chart from "chart.js/auto";
import "chartjs-adapter-date-fns";

export default {
  name: "TelemetryChart",
  props: ["title", "data", "ticks"],
  setup(props) {
    const canvas = ref(null);
    let chart;

    onMounted(() => {
      chart = new Chart(canvas.value, {
        type: "bar",
        data: props.data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (value) {
                  return props.ticks.get(value);
                },
              },
            },
            x: {
              type: "time",
              time: {
                unit: "hour",
              },
            },
          },
          maintainAspectRatio: false,
        },
      });
    });
    onUnmounted(() => {
      chart.destroy();
    });

    return {
      canvas,
    };
  },
};
</script>
