import { queryAlarmCenters } from "@/api/arcs";

const state = () => ({
  all: [],
});

const getters = {};

const actions = {
  async getAlarmCenters({ commit, state }) {
    if (state.all.length === 0) {
      const arcs = await queryAlarmCenters();
      commit("setAlarmCenters", arcs);
    }
  },
};

const mutations = {
  setAlarmCenters(state, arcs) {
    state.all = arcs;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
