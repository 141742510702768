import Devices from "./views/Devices.vue";
import Device from "./views/Device.vue";
import Users from "./views/Users.vue";
import User from "./views/User.vue";
import AlertDestinationEditor from "./components/AlertDestinationsEditor.vue";
import BraceletsEditor from "./components/BraceletsEditor.vue";
import NotificationEditor from "./components/NotificationEditor.vue";
import AlarmCenters from "./views/AlarmCenters.vue";
import AlarmCenter from "./views/AlarmCenter.vue";
import NotFound from "./views/NotFound.vue";
import DeviceTabGeneral from "./components/DeviceTabGeneral";
import DeviceTabAlarm from "@/components/DeviceTabAlarm";
import DeviceTabBracelet from "@/components/DeviceTabBracelet";
import DeviceTabNotificaiton from "@/components/DeviceTabNotification";
import DeviceTabFeatures from "@/components/DeviceTabFeatures";
import { createRouter, createWebHistory } from "vue-router";
import { AuthFilter } from "@/lib/auth";
import Store from "@/store";

const routes = [
  { path: "/", component: Devices, meta: { title: "Devices" }, name: "devices" },
  {
    path: "/device/:serialNumber",
    component: Device,
    meta: { title: "Device" },
    beforeEnter: async (to, from) => {
      if (to.params.serialNumber !== from.params.serialNumber) {
        await Store.dispatch("device/getDevice", to.params.serialNumber);
      }
    },
    children: [
      {
        path: "",
        component: DeviceTabGeneral,
        name: "device",
      },
      {
        path: "alarm",
        component: DeviceTabAlarm,
        name: "deviceTabAlarm",
        beforeEnter: async () => {
          await Store.dispatch("arc/getAlarmCenters");
        },
      },
      {
        path: "bracelet",
        component: DeviceTabBracelet,
        name: "deviceTabBracelet",
      },
      {
        path: "notification",
        component: DeviceTabNotificaiton,
        name: "deviceTabNotification",
      },
      {
        path: "features",
        component: DeviceTabFeatures,
        name: "deviceTabFeatures",
      },
    ],
  },
  {
    path: "/alarmcenters",
    component: AlarmCenters,
    meta: { title: "Alarm Centers" },
    name: "alarmcenters",
  },
  { path: "/alarmcenters/:arcId", component: AlarmCenter, meta: { title: "Alarm Center" }, name: "arc", props: true },
  { path: "/users", component: Users, meta: { title: "Users" }, name: "users" },
  {
    path: "/user/:id",
    component: User,
    meta: { title: "User" },
    name: "user",
    props: true,
    beforeEnter: async (to, from) => {
      if (to.params.id !== from.params.id) {
        await Store.dispatch("loadTenants");
        await Store.dispatch("users/loadUser", to.params.id);
      }
    },
  },
  { path: "/:path(.*)", component: NotFound, meta: { title: "Not Found" } },
  // Isolated components with mock data for development
  { path: "/dev/bracelets", component: BraceletsEditor },
  { path: "/dev/alertdestination", component: AlertDestinationEditor },
  { path: "/dev/notification", component: NotificationEditor },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(AuthFilter);

export default router;
