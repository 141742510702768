<template>
  <teleport to="#tools">
    <div class="flex items-center gap-5 px-4 h-11 border-b border-gray-200">
      <button
        type="button"
        class="
          inline-flex
          items-center
          p-1
          border border-transparent
          rounded-full
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
        "
        @click="refreshDevice"
      >
        <RefreshIcon :class="[deviceLoading ? 'animate-spin' : '', 'h-5 w-5']" aria-hidden="true" />
      </button>

      <button
        @click="diagnose"
        type="button"
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-gray-300
          shadow-sm
          text-xs
          font-medium
          rounded
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        Diagnose
      </button>
      <button
        type="button"
        @click="alertOpen = true"
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-gray-300
          shadow-sm
          text-xs
          font-medium
          rounded
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        Reset
      </button>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-gray-300
          shadow-sm
          text-xs
          font-medium
          rounded
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
        @click="showCopyConfig = true"
      >
        Copy Config
      </button>
      <button
        type="button"
        class="
          inline-flex
          items-center
          px-2.5
          py-1.5
          border border-gray-300
          shadow-sm
          text-xs
          font-medium
          rounded
          text-gray-700
          bg-white
          hover:bg-gray-50
          focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
        "
      >
        <DotsVerticalIcon class="h-4 w-4"></DotsVerticalIcon>
      </button>
    </div>
  </teleport>
  <DeviceSettingTabs :device-id="deviceId" />
  <router-view></router-view>
  <DeviceCopyConfigSlide :open="showCopyConfig" @cancel="showCopyConfig = false" />
  <SimpleAlert
    @cancel="alertOpen = false"
    @approve="reset"
    :open="alertOpen"
    title="Reset Device"
    description="Are you sure you want to reset this device? This action cannot be undone."
    action="Apply"
  ></SimpleAlert>
</template>

<script>
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import { RefreshIcon } from "@heroicons/vue/outline";
import DeviceSettingTabs from "../components/DeviceSettingTabs.vue";
import { computed, ref } from "vue";
import DeviceCopyConfigSlide from "@/views/DeviceCopyConfigSlide";
import { useStore } from "vuex";
import { diagnoseDevice, resetDevice } from "@/api/device";
import SimpleAlert from "@/components/modals/Alert";

export default {
  components: {
    DeviceCopyConfigSlide,
    DeviceSettingTabs,
    DotsVerticalIcon,
    RefreshIcon,
    SimpleAlert,
  },
  props: ["deviceId"],
  setup() {
    const alertOpen = ref(false);
    const showCopyConfig = ref(false);
    const store = useStore();

    const deviceLoading = computed(() => store.state.device.loading);

    const diagnose = () => {
      diagnoseDevice([store.state.device.current.id]);
      store.commit("showNotification", {
        title: `Device diagnosis started...`,
        type: "info",
      });
    };

    const reset = () => {
      resetDevice([store.state.device.current.id]);
      store.commit("showNotification", {
        title: `Device reset started...`,
        type: "info",
      });
    };

    const refreshDevice = async () => {
      const serialNumber = store.state.device.current.serialNumber;
      await store.dispatch("device/reloadDevice", serialNumber);
      store.commit("showNotification", {
        title: `${serialNumber} updated`,
        type: "info",
      });
    };

    return {
      alertOpen,
      reset,
      diagnose,
      showCopyConfig,
      deviceLoading,
      refreshDevice,
    };
  },
};
</script>
