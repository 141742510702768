<template>
  <h1 class="text-lg leading-6 font-medium text-gray-900 mb-5">Plugout Notifications</h1>

  <div
    v-for="(notification, index) in notifications"
    :key="notification.$uuid"
    @keyup="validateReceiverAt(index)"
    @change="validateReceiverAt(index)"
  >
    <div class="flex">
      <select
        class="block focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
        v-model="notification.type"
        @change="notification.destination = ''"
      >
        <option value="sms">SMS</option>
        <option value="email">Email</option>
      </select>
      <input
        v-model="notification.destination"
        type="text"
        class="w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md ml-2"
      />
      <button class="flex items-center ml-1" @click="removeReceiverAt(index)">
        <MinusCircleIcon class="h-6 w-6 text-gray-500" aria-hidden="true" />
      </button>
    </div>
    <div class="h-6 mt-1 mb-2">
      <div class="flex text-red-500 text-sm items-center" v-if="notification.$error">
        <ExclamationCircleIcon class="h-5 w-5" aria-hidden="true" />
        <p class="ml-1">{{ notification.$error }}</p>
      </div>
    </div>
  </div>

  <button
    @click="appendNewReceiver"
    class="
      mt-2
      bg-white
      py-2
      w-full
      border border-gray-300
      rounded-md
      shadow-sm
      text-sm
      font-medium
      text-gray-700
      hover:bg-gray-50
    "
  >
    Add Notification Receiver
  </button>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { ExclamationCircleIcon, MinusCircleIcon } from "@heroicons/vue/outline";
import { reactive, watchEffect } from "vue";

const isValidSmsReceiver = (destination) =>
  !isNaN(destination) && destination.startsWith("00") && destination.length > 6;

const isValidEmailReceiver = (destination) =>
  isNaN(destination) && destination.includes("@") && destination.includes(".");

export default {
  components: {
    MinusCircleIcon,
    ExclamationCircleIcon,
  },
  props: {
    modelValue: {
      default: [
        { type: "sms", destination: "00411111111" },
        { type: "email", destination: "sepp@fifa.com" },
      ],
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const notifications = reactive(
      props.modelValue.map((item) => ({
        $uuid: uuidv4(),
        $error: "",
        ...item,
      }))
    );

    watchEffect(() => emit("update:modelValue", notifications));

    return {
      notifications,

      validateReceiverAt: (index) => {
        const { type, destination } = notifications[index];
        if (destination === "") {
          notifications[index].$error = "Destination cannot be empty.";
        } else if (type === "sms" && !isValidSmsReceiver(destination)) {
          notifications[index].$error = "Phone number not valid. Expected format: 004178021212";
        } else if (type === "email" && !isValidEmailReceiver(destination)) {
          notifications[index].$error = "Email address not valid. Expected format: user@host.com";
        } else {
          notifications[index].$error = "";
        }
      },
      removeReceiverAt: (index) => {
        notifications.splice(index, 1);
      },
      appendNewReceiver: () => {
        notifications.push({
          type: "sms",
          destination: "",
          $error: "Receiver cannot be empty.",
          $uuid: uuidv4(),
        });
      },
    };
  },
};
</script>
