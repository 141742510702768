<template>
  <teleport to="#tools">
    <div class="flex items-center gap-5 px-4 h-11 border-b border-gray-200">
      <input
        type="checkbox"
        v-model="fullSelection"
        :class="{ partial: partialSelection }"
        class="rounded text-pink-500"
      />
      <button
        type="button"
        class="
          inline-flex
          items-center
          p-1
          border border-transparent
          rounded-full
          text-gray-700
          hover:bg-gray-50
          focus:outline-none
        "
        @click="reload"
      >
        <RefreshIcon :class="[devicesLoading ? 'animate-spin' : '', 'h-5 w-5']" aria-hidden="true" />
      </button>
      <template v-if="fullSelection">
        <button
          @click="batchDiagnosis"
          type="button"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          "
        >
          Diagnose
        </button>
        <button
          type="button"
          @click="batchEdit('group')"
          class="
            inline-flex
            items-center
            px-2.5
            py-1.5
            border border-gray-300
            shadow-sm
            text-xs
            font-medium
            rounded
            text-gray-700
            bg-white
            hover:bg-gray-50
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
          "
        >
          Group
        </button>
        <Menu as="div" class="relative inline-block text-left">
          <div>
            <MenuButton
              class="
                inline-flex
                justify-center
                w-full
                rounded-md
                border border-gray-300
                shadow-sm
                px-2.5
                py-1.5
                bg-white
                text-sm
                font-medium
                text-gray-700
                hover:bg-gray-50
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500
              "
            >
              <DotsVerticalIcon class="h-4 w-4" aria-hidden="true" />
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="
                origin-top-right
                absolute
                right-0
                mt-2
                w-56
                rounded-md
                shadow-lg
                bg-white
                ring-1 ring-black ring-opacity-5
                focus:outline-none
                divide-y divide-gray-100
              "
            >
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <button
                    @click="batchEdit('alarm')"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm w-full text-left',
                    ]"
                  >
                    Alarm Config
                  </button>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="batchEdit('notification')"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Notifications</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    @click="batchReset"
                    href="#"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Reset</a
                  >
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="batchReboot"
                    href="#"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Reboot
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    @click="batchShutdown"
                    href="#"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Shutdown</a
                  >
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem v-slot="{ active }">
                  <a
                    @click="batchDeactivate"
                    href="#"
                    :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']"
                    >Deactivate</a
                  >
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </template>
    </div>
  </teleport>
  <div v-if="devicesLoading">
    <h3 class="px-4 py-3 text-sm font-light">Loading devices...</h3>
    <DeviceListPlaceholder />
  </div>
  <template v-else>
    <TransitionRoot as="template" :show="showSlideIn">
      <Dialog
        as="div"
        static
        class="fixed inset-0 overflow-hidden z-20"
        @close="showSlideIn = false"
        :open="showSlideIn"
      >
        <div class="absolute inset-0 overflow-hidden">
          <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <div class="w-screen max-w-lg">
                <div class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <component :is="slideInBatchEditor" @close="showSlideIn = false" :deviceSelection="deviceSelection" />
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <h3 class="px-4 py-3 text-sm font-light" v-if="deviceSelection.length">
      {{ deviceSelection.length }} of {{ devices.length }} devices selected
    </h3>
    <h3 class="px-4 py-3 text-sm font-light" v-else>{{ devices.length }} devices</h3>

    <table class="w-full">
      <tbody class="bg-gray-50 divide-y divide-gray-200">
        <tr
          @click="deviceDetail(device, $event)"
          v-for="device in devices"
          :key="device.id"
          class="hover:bg-gray-100 hover:shadow-list cursor-pointer"
        >
          <td data-interactive class="px-4 py-2 w-12">
            <input type="checkbox" class="block rounded text-pink-500" :value="device.id" v-model="deviceSelection" />
          </td>
          <device-list-item :device="device" />
        </tr>
      </tbody>
    </table>
  </template>
  <SimpleAlert
    @cancel="alertOptions.showAlert = false"
    @approve="
      alertOptions.approve();
      alertOptions.showAlert = false;
    "
    :open="alertOptions.showAlert"
    :title="alertOptions.title"
    :description="alertOptions.description"
    action="Apply"
  ></SimpleAlert>
</template>

<script>
import DeviceListItem from "../components/DeviceListItem.vue";
import { computed, ref, shallowRef } from "vue";
import { useStore } from "vuex";
import { RefreshIcon } from "@heroicons/vue/outline";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import { Dialog, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot } from "@headlessui/vue";
import AlertDestinationsBatch from "@/components/AlertDestinationsBatch";
import NotificationBatch from "../components/NotificationBatch";
import GroupNotificationBatch from "../components/GroupBatch";
import { searchDevices } from "@/lib/search";
import { useRouter } from "vue-router";
import DeviceListPlaceholder from "@/components/DeviceListPlaceholder";
import SimpleAlert from "@/components/modals/Alert";
import { deactivateDevice, rebootDevice, resetDevice, shutdownDevice, diagnoseDevice } from "@/api/device";

const batchEditors = {
  alarm: AlertDestinationsBatch,
  notification: NotificationBatch,
  group: GroupNotificationBatch,
};

export default {
  components: {
    DeviceListPlaceholder,
    DeviceListItem,
    DotsVerticalIcon,
    RefreshIcon,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Dialog,
    TransitionChild,
    TransitionRoot,
    SimpleAlert,
  },
  setup() {
    const showSlideIn = ref(false);
    const slideInBatchEditor = shallowRef(null);
    const store = useStore();
    const router = useRouter();
    const deviceSelection = ref([]);
    const devices = computed(() => searchDevices(store.state.searchQueryData, store.state.device.all));
    const devicesLoading = computed(() => store.state.device.loading);
    const partialSelection = computed(() => {
      return deviceSelection.value.length > 0 && devices.value.length !== deviceSelection.value.length;
    });

    const alertOptions = ref({
      showAlert: false,
      title: "",
      description: "",
      approve: () => {},
    });

    const fullSelection = computed({
      get: () => deviceSelection.value.length > 0,
      set: (checked) => (deviceSelection.value = checked ? devices.value.map((device) => device.id) : []),
    });

    const batchEdit = (editor) => {
      slideInBatchEditor.value = batchEditors[editor];
      showSlideIn.value = true;
    };

    store.dispatch("device/getAllDevices");

    return {
      alertOptions,
      deviceDetail(device, event) {
        const target = event.target;
        if (target.tagName.toLowerCase() === "input" || "interactive" in target.dataset) {
          return;
        }
        router.push({ name: "device", params: { serialNumber: device.serialNumber } });
      },
      showSlideIn,
      slideInBatchEditor,
      batchEdit,
      devices,
      devicesLoading,
      deviceSelection,
      partialSelection,
      fullSelection,
      reload() {
        store.dispatch("device/getAllDevices", true);
      },
      batchDiagnosis() {
        store.commit("showNotification", {
          title: `Initiated diagnosis for ${deviceSelection.value.length}.`,
          type: "info",
        });
        diagnoseDevice(deviceSelection.value);
      },
      batchReset() {
        alertOptions.value.showAlert = true;
        alertOptions.value.title = "Device Reset";
        alertOptions.value.description = `Are you sure you want to reset ${deviceSelection.value.length} devices? This action cannot be undone.`;
        alertOptions.value.approve = () => {
          resetDevice(deviceSelection.value);
        };
      },
      batchReboot() {
        alertOptions.value.showAlert = true;
        alertOptions.value.title = "Device Reboot";
        alertOptions.value.description = `Are you sure you want to reboot ${deviceSelection.value.length} devices?`;
        alertOptions.value.approve = () => {
          rebootDevice(deviceSelection.value);
        };
      },
      batchShutdown() {
        alertOptions.value.showAlert = true;
        alertOptions.value.title = "Device Shutdown";
        alertOptions.value.description = `Are you sure you want to shutdown ${deviceSelection.value.length} devices?`;
        alertOptions.value.approve = () => {
          shutdownDevice(deviceSelection.value);
        };
      },
      batchDeactivate() {
        alertOptions.value.showAlert = true;
        alertOptions.value.title = "Device Deactivate";
        alertOptions.value.description = `Are you sure you want to deactivate ${deviceSelection.value.length} devices?`;
        alertOptions.value.approve = () => {
          deactivateDevice(deviceSelection.value);
        };
      },
    };
  },
};
</script>

<style lang="scss">
[type="checkbox"].partial:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='m 4.5,7 h 7 c 0.554,0 1,0.446 1,1 0,0.554 -0.446,1 -1,1 h -7 c -0.554,0 -1,-0.446 -1,-1 0,-0.554 0.446,-1 1,-1 z'/%3e%3c/svg%3e");
}
</style>
